<template>
    <div class="container-fluid d-flex flex-column">


        <div class="row justify-content-center min-vh-100">
            <div class="col-md-6 col-lg-5 col-xl-4 py-6 py-md-0 mt-5">
                <div class="card" style="max-width: 600px; margin: 0 auto;">
                    <div class="card-body">

                        <div class="mb-5 mt-5 text-center">
                            <h3 class="h3-green" style="font-size:40px;">Registreren</h3>
                            <p><strong>Maak een account aan en ontvang gratis 10 credits!</strong></p>
                        </div>

                        <div>

                            <span class="clearfix"></span>

                            <ValidationObserver ref="register" tag="form">

                                <form>

                                    <div class="form-group mb-3">
                                        <label class="form-control-label">Bedrijfsnaam: <sup>*</sup></label>
                                        <div class="">
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <input :disabled="loading" type="text" v-model="request.company" class="form-control" id="company" name="company" placeholder="" @keydown.enter="register">
                                                <small class="error" v-if="errors[0] || errorMessages['company']">{{ errors[0] || errorMessages['company'][0] }}</small>
                                            </validation-provider>
                                        </div>
                                    </div>


                                    <div class="form-group mb-3">
                                        <label class="form-control-label">Naam: <sup>*</sup></label>
                                        <div class="">
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <input :disabled="loading" type="text" v-model="request.name" class="form-control" id="name" name="name" placeholder="" @keydown.enter="register">
                                                <small class="error" v-if="errors['name'] || errorMessages['name']">{{ errors['name'] || errorMessages['name'][0] }}</small>
                                            </validation-provider>
                                        </div>
                                    </div>

                                    <div class="form-group mb-3">
                                        <label class="form-control-label">Emailadres: <sup>*</sup></label>
                                        <div class="">
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <input :disabled="loading" type="email" v-model="request.email" class="form-control" id="email" name="email" placeholder="" @keydown.enter="register">
                                                <small class="error" v-if="errors[0] || errorMessages['email']">{{ errors[0] || errorMessages['email'][0] }}</small>
                                            </validation-provider>
                                        </div>
                                    </div>

                                    <div class="form-group mb-3">
                                      <label class="form-control-label">Telefoonnummer:</label>
                                      <div class="">
                                        <validation-provider v-slot="{ errors }">
                                          <input :disabled="loading" rules="maxlength:50" type="tel" v-model="request.phone" class="form-control input-text" id="phone" name="phone" placeholder="" @keydown.enter="register">
                                          <small class="error" v-if="errors[0] || errorMessages['phone']">{{ errors[0] || errorMessages['phone'][0] }}</small>
                                        </validation-provider>
                                      </div>
                                    </div>

                                    <div class="form-group mb-3">
                                        <label class="form-control-label">Wachtwoord: <sup>*</sup></label>
                                        <div class="">
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <input :disabled="loading" type="password" v-model="request.password" class="form-control" id="password" name="password" placeholder="" @keyup.enter="register()">
                                                <small class="error" v-if="errors[0] || errorMessages['password']">{{ errors[0] || errorMessages['password'][0] }}</small>
                                            </validation-provider>
                                        </div>
                                    </div>


                                    <div class="form-group mb-3">
                                        <label class="form-control-label">Wachtwoord herhalen: <sup>*</sup></label>
                                        <div class="">
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <input :disabled="loading" type="password" v-model="request.passwordconfirmation" class="form-control" id="passwordconfirmation" name="password" placeholder="" @keyup.enter="register()">
                                                <small class="error" v-if="errors[0] || errorMessages['passwordconfirmation']">{{ errors[0] || errorMessages['passwordconfirmation'][0] }}</small>
                                            </validation-provider>
                                        </div>
                                    </div>

                                    <div class="form-group mb-3">
                                        <validation-provider rules="terms" v-slot="{ errors }">
                                            <input type="checkbox" class="custom-checkbox" name="terms" id="terms" v-model="request.terms">
                                            <label class="form-control-label" for="terms">
                                                Ik ga akkoord met de <a :href="termsLink" target="_blank">algemene voorwaarden</a>
                                            </label>

                                            <small class="error d-block" v-if="errors[0] || errorMessages['terms']">{{ errors[0] || errorMessages['terms'][0] }}</small>
                                        </validation-provider>
                                    </div>

                                    <div class="form-group mb-3">
                                        <input type="checkbox" class="custom-checkbox" name="two_factor_auth" id="two_factor_auth" v-model="request.two_factor_auth">
                                        <label class="form-control-label" for="two_factor_auth">
                                            Tweestapsauthenticatie activeren
                                        </label>
                                    </div>

                                    <div class="alert alert-danger mt-2" v-if="errormessage">{{ errormessage }}</div>

                                    <div class="mt-4">
                                        <button type="button" @click="register" class="btn btn-block btn-primary btn-caps" :disabled="loading" id="register-button">Registreer</button>
                                    </div>

                                    <div class="text-center form-group mt-3">
                                        <small>
                                            <router-link :to="`/login`" id="register-login-link">Inloggen</router-link>
                                        </small>
                                    </div>


                                </form>

                            </ValidationObserver>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
    import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';
    import {mapState} from 'vuex';

    extend('required', {
        ...required,
        message: 'Dit veld is verplicht'
    });

    extend('terms', {
        validate(value) {
            return value === true;
        },
        message: 'Akkoord gaan met de algemene voorwaarden is verplicht'
    });

    export default {

        components: {
            ValidationProvider,
            ValidationObserver,
        },

        computed: mapState({
            termsLink: state => state.termsLink
        }),

        data() {
            return {
                request: {
                    email: null,
                    phone: null,
                    password: null,
                    terms: false,
                },
                errormessage: null,
                errorMessages: {},
                loading: false,
            };
        },

        methods: {
            async register($event) {
                this.errorMessages = {};
                this.errormessage = null;

                if ($event) {
                    $event.preventDefault();
                }

                if (!this.passwordConfirmed()) {
                    this.errormessage = 'De wachtwoorden komen niet overeen';
                    return;
                }

                this.loading = true;

                try {
                    const response = await this.$http.post(this.$root.api_url + '/api/auth/register', this.request);

                    await this.$store.dispatch('login', {
                        user: response.data.user,
                        token: response.data.token
                    });

                    await this.$router.push('/nieuwe-ondertekening?register=success');
                } catch (exception) {
                    if (exception?.response?.status === 422) {
                        this.errorMessages = exception?.response?.data?.errors;
                        this.errormessage = 'Je hebt niet alle velden correct ingevuld';
                    } else {
                        this.errormessage = 'Er is iets mis gegaan. Probeer het opnieuw of neem contact met ons op.';
                    }
                }

                this.loading = false;
            },

            passwordConfirmed() {
                return (this.request.password === this.request.passwordconfirmation);
            }
        }
    };

</script>

<style>

</style>
