import './utils/sentry'
import './assets/css/bootstrap.css'
import '../node_modules/vue-multiselect/dist/vue-multiselect.min.css'
import 'bootstrap';
import './assets/css/compiled.css'
import './assets/css/menu.css'
import 'flag-icons/css/flag-icons.min.css';
import moment from 'moment'
moment.locale('nl')


import Vue from 'vue'
import Vuex from 'vuex';
import App from './App.vue'
import routes from './router'
import VueRouter from 'vue-router'
import appStore from './store'
import VueTrumbowyg from 'vue-trumbowyg'
import Multiselect from 'vue-multiselect'
import Notifications from 'vue-notification'
import VTooltip from 'v-tooltip'
import 'trumbowyg/dist/ui/trumbowyg.css';

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import './assets/css/vue-ctk-date-time-picker.css';

// import Axios from 'axios'
import authMiddleware from "./utils/auth";
import axiosClient from "./utils/axios";
// import dataLoadMiddleware from "./utils/dataLoader";
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faMoneyBill,
    faUser,
    faBuilding,
    faBars,
    faEject,
    faChevronLeft,
    faChevronRight,
    faCreditCard,
    faDownload,
    faFileInvoice,
    faKey,
    faMailBulk,
    faPaperPlane,
    faPencilAlt,
    faPlus,
    faReplyAll,
    faSearch,
    faTrash,
    faUserCircle,
    faUserSecret,
    faWindowClose,
    faFileContract,
    faQuestionCircle,
    faCopy,
    faUsers,
    faHeadset,
    faPaste,
    faFileAlt,
    faInfo,
    faSave
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import dataLoadMiddleware from "./utils/dataLoader";
import featureMiddleware, {hasFeature} from "./utils/feature";

require('./assets/trumbowyg/plugins/mergefields/trumbowyg.mergefields');
// require('./assets/trumbowyg/plugins/files/trumbowyg.files');
Vue.use(VueTrumbowyg);
Vue.use(Notifications)

library.add(
    faUserSecret,
    faPencilAlt,
    faUsers,
    faPlus,
    faPaperPlane,
    faTrash,
    faSearch,
    faChevronLeft,
    faBars,
    faUser,
    faChevronRight,
    faDownload,
    faWindowClose,
    faUserCircle,
    faBuilding,
    faFileInvoice,
    faMailBulk,
    faCreditCard,
    faKey,
    faReplyAll,
    faEject,
    faFileContract,
    faQuestionCircle,
    faCopy,
    faHeadset,
    faPaste,
    faFileAlt,
    faInfo,
    faMoneyBill,
    faSave
)


Vue.use(require('vue-moment'), {
    moment
})

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('multiselect', Multiselect)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.use(VueRouter);
Vue.use(VTooltip)

Vue.config.productionTip = false

export const store = new Vuex.Store(appStore);

const router = new VueRouter({
    routes,
    mode: 'history'
});

Vue.prototype.$http = new axiosClient(store, router);

router.beforeEach((to, from, next) => authMiddleware(store, to, from, next));
router.beforeEach((to, from, next) => dataLoadMiddleware(store, to, from, next, [
    'company',
    'locales'
]));
router.beforeEach((to, from, next) => featureMiddleware(store, to, from, next));

Vue.prototype.hasFeature = hasFeature;

export const formatCurrency = (value) =>  {
    if (value !== null && value !== undefined) {
        const tempvalue = value.toString().replace(',', '.');
        const newValue = parseFloat(tempvalue).toFixed(2);
        return `€ ${newValue.toString().replace('.', ',')}`;
    }

    return value;
};

// Format Currency filter for display
Vue.filter('formatCurrency', formatCurrency);

new Vue({
    router,
    store,
    data: function () {
        return {
            modals: [],
            api_url: process.env.VUE_APP_API_URL || '',
            default_editor_config: {
                btns: [
                    ['viewHTML'],
                    ['undo', 'redo'], // Only supported in Blink browsers
                    ['formatting'],
                    ['strong', 'em', 'del'],
                    ['superscript', 'subscript'],
                    ['link'],
                    ['files'],
                    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                    ['unorderedList', 'orderedList'],
                    ['horizontalRule'],
                    ['removeformat'],
                    ['fullscreen']
                ],
                removeformatPasted: true,
                plugins: {
                    files: {
                        uploadPath: '/backoffice/editor/images'
                    }
                },
                tagsToRemove: [
                    'script',
                    'link',
                    'meta',
                    'form',
                    'input',
                    'textarea'
                ]
            }
        }
    },

    methods: {
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
        },

    },

    render: h => h(App)
}).$mount('#app')
