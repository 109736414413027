<template>
    <div>
        <div v-if="showLabel">
            <embed
                :src=signable.src
                width="350"
                height="250">
            <br>
            <strong>{{ signable.filename }}</strong> <br>
            Bestandsgrootte: {{ $root.formatBytes(signable.size) }}
            <div>

                <button
                    @click="renderVariablesPdf"
                    v-if="signable.file.type === 'application/pdf'"
                    class="btn btn-outline-primary btn-sm margin-right-3"
                >
                    Formuliermarkers
                </button>

                <button class="btn btn-outline-danger btn-sm" @click="$emit('delete')">Verwijder bestand</button>
            </div>
        </div>

        <modal :show="showVariablesModal"
               @close="showVariablesModal = false; $emit('close')"
               @opened="resizePageContainer"
               :custom_modal_class="'modal-xl'"
        >
            <div slot="header">
                <div class="modal-header">
                    <h5 class="modal-title mt-0">Formuliermarkers instellen</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showVariablesModal = false"><span aria-hidden="true">×</span></button>
                </div>
            </div>

            <div slot="body" class="signable-variables">
                <div class="alert alert-info" style="text-align: left; line-height: 18px;" v-if="showUploadText">
                    <span v-if="uploaded">Het bestand is succesvol ge-upload. </span>Indien van toepassingen is het mogelijk om formuliermarkers te slepen op het document. Na het plaatsen van de formuliermarkers op het document is het mogelijk om deze van grootte te veranderen. Je kunt ook voor de formuliermarkers kiezen uit eerdere documenten of templates.
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <slot name="above-variables"/>

                        <div v-if="usedMarkers.templates.length || usedMarkers.signables.length" class="summary-box mb-3 text-left variables-custom">
                            <h2 class="title-heading-left mb-3">Formuliermarkers hergebruiken</h2>

                            <multiselect
                                :options="usedMarkers.templates"
                                label="templateName"
                                track-by="id"
                                :searchable="true"
                                :multiple="false"
                                placeholder="Selecteer van een template"
                                :custom-label="option => `${option.name} - ${moment(option.created_at).format('YYYY-MM-DD')}`"
                                @input="(option) => handleSelectVariable(option)"
                                @search-change="(searchQuery) => debouncedGetTemplatesVariables(searchQuery)"
                            />

                            <multiselect
                                class="mt-2"
                                :options="usedMarkers.signables"
                                label="signableName"
                                track-by="id"
                                :searchable="true"
                                :multiple="false"
                                placeholder="Selecteer van een document"
                                :custom-label="option => `${option.name} - ${moment(option.created_at).format('YYYY-MM-DD')}`"
                                @input="(option) => handleSelectVariable(option)"
                                @search-change="(searchQuery) => debouncedGetSignablesVariables(searchQuery)"
                            />
                        </div>

                        <div class="summary-box mb-3 text-left variables-custom">
                            <h2 class="title-heading-left mb-3">Losse formuliermarkers</h2>

                            <div
                                class="variable-box"
                                draggable="true"
                                @dragstart="startDraggingSignerVariable($event, {
                                    type: 'text',
                                    value: null,
                                    options: {
                                        minWidth: 1,
                                        minHeight: 1,
                                    }
                                })"
                            >
                                Tekst
                            </div>
                            <div
                                class="variable-box"
                                draggable="true"
                                @dragstart="startDraggingSignerVariable($event, {
                                    type: 'date',
                                    value: null,
                                    options: {
                                        format: 'DD-MM-YYYY',
                                    }
                                })"
                            >
                                Datum
                            </div>
                            <div
                                class="variable-box"
                                draggable="true"
                                @dragstart="startDraggingSignerVariable($event, {
                                    type: 'date',
                                    value: null,
                                    options: {
                                        format: 'DD-MM-YYYY HH:mm',
                                    }
                                })"
                            >
                                Datum + tijd
                            </div>
                            <div
                                class="variable-box"
                                draggable="true"
                                @dragstart="startDraggingSignerVariable($event, {
                                    type: 'checkbox_checked',
                                    value: 'checked',
                                    options: {
                                        minWidth: 1,
                                        minHeight: 1,
                                        defaultWidth: 14,
                                        defaultHeight: 14,
                                        keepRatio: true
                                    }
                                })"
                            >
                                Checkbox vinkje
                            </div>
                        </div>

                        <div class="variables-signers">
                            <div v-for="(signer, index) of signers" class="summary-box mb-3 text-left variable-signer" :key="index">
                                <h2 class="title-heading-left mb-3">{{ signer.firstname }} {{ signer.lastname }}</h2>

                                <div v-for="(variable, variableIndex) of getSignerVariables(index, signer)"
                                     :key="index + '_' + variableIndex"
                                     class="variable-box"
                                     draggable="true"
                                     @dragstart="startDraggingSignerVariable($event, variable)"
                                >
                                    <div>
                                        <div class="variable-name">
                                            {{ variable.name }}
                                        </div>
                                        <small class="variable-value">{{ variable.value }}</small>
                                    </div>

                                    <button class="btn btn-primary btn-sm"
                                            style="margin-left: auto"
                                            v-if="variable.renderType === 'paraph'"
                                            v-tooltip="'Paraaf automatisch toevoegen aan meerdere pagina\'s'"
                                            @click="showAddParaphModal(variable)"
                                    >
                                        <font-awesome-icon icon="file-alt"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 col-md-8">
                        <div ref="allContainer" class="all-container">
                            <div ref="pageContainer" class="page-container">
                                <div id="viewer" class="pdfViewer"></div>
                            </div>

                            <div ref="datePickerComponent" class="variable-date-picker">
                                <VueCtkDateTimePicker
                                    ref="datePickerVueComponent"
                                    :color="'#004188'"
                                    :button-color="'#004188'"
                                    format="YYYY-MM-DD HH:mm:ss"
                                    :formatted="variableDatePickerComponentFormat"
                                    v-model="variableDatePickerComponentValue"
                                    @input="variableDateChanged"
                                    :only-date="variableDatePickerComponentFormat ? variableDatePickerComponentFormat.indexOf('HH:mm') === -1 : false"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <modal :show="addParaphVariable"
                       @close="addParaphVariable = null"
                       :custom_modal_class="'modal-xs modal-dialog-centered'"
                >
                    <div slot="header">
                        <div class="modal-header">
                            <h5 class="modal-title mt-0">Paraaf toevoegen op pagina's</h5>

                            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="addParaphVariable = null">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    </div>

                    <div slot="body" class="text-left">
                        <div>
                            <label>Pagina's:</label>
                            <select class="form-control" v-model="addParaphPages">
                                <option value="all">Alle</option>
                                <option value="even">Even</option>
                                <option value="odd">Oneven</option>
                                <option value="custom">Aangepast</option>
                            </select>
                        </div>

                        <div v-if="addParaphPages === 'custom'" class="mt-4">
                            <label>Toevoegen op:</label>
                            <input class="form-control" v-model="addParaphPagesCustom"/>
                            <small class="d-block">Voorbeeld: 1-5, 10, 14-18</small>
                        </div>
                    </div>

                    <div slot="footer" class="col-12">
                        <div style="padding-left: 0.5rem">
                            <button type="button" class="btn btn-outline-secondary float-start" @click="addParaphVariable = null">Sluiten</button>

                            <button type="button" class="btn btn-primary float-end" @click="addParaphOnPages">Toevoegen</button>
                        </div>
                    </div>

                </modal>
            </div>

            <div slot="footer" class="col-12">
                <div style="padding-left: 0.5rem">
                    <button type="button" class="btn btn-outline-secondary float-start" data-dismiss="modal" @click="showVariablesModal = false">Sluiten</button>

                    <button type="button" class="btn btn-primary float-end" data-dismiss="modal" @click="showVariablesModal = false; $emit('save')">{{ saveButtonText }}</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>

    import Modal from '@/components/Modal';
    import pdfjsLib from 'pdfjs-dist/build/pdf';
    import {PDFViewer} from 'pdfjs-dist/web/pdf_viewer';
    import 'pdfjs-dist/web/pdf_viewer.css';
    import {debounce} from "lodash";
    import moment from "moment";

    export default {
        computed: {
            moment() {
                return moment
            }
        },

        components: {
            Modal
        },

        props: {
            value: {
                type: Object,
                default: null
            },

            signable: {
                type: Object,
                required: true
            },

            signers: {
                type: Array,
                default() {
                    return [];
                }
            },

            showLabel: {
                type: Boolean,
                default: true
            },

            saveButtonText: {
                type: String,
                default: 'Doorgaan'
            },

            showUploadText: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                localValue: JSON.parse(JSON.stringify(this.value)),
                showVariablesModal: false,
                pdfViewer: null,
                currentResizer: null,

                variableDatePickerComponentValue: null,
                variableDatePickerComponentFormat: null,
                currentSelectedVariable: null,

                uploaded: false,

                addParaphVariable: null,
                addParaphPages: 'all',
                addParaphPagesCustom: null,

                usedMarkers: {
                    templates: [],
                    signables: [],
                    addedOptions: [],
                },
                debouncedGetTemplatesVariables: null, // Placeholder for the debounced function
                debouncedGetSignablesVariables: null // Placeholder for the debounced function
            };
        },

        watch: {
            value: {
                deep: true,
                handler() {
                    this.localValue = JSON.parse(JSON.stringify(this.value));
                    this.updateVariableElements();
                }
            }
        },

        mounted() {
            window.addEventListener('resize', this.resizePageContainer);

            if (this.signable.file.type === 'application/pdf' && this.signable.showModal) {
                this.renderVariablesPdf(true);
            }

            this.getTemplatesVariables();
            this.getSignablesVariables();
        },

        created() {
            // Create debounced versions of the methods
            this.debouncedGetTemplatesVariables = debounce(this.getTemplatesVariables, 300);
            this.debouncedGetSignablesVariables = debounce(this.getSignablesVariables, 300);
        },

        beforeDestroy() {
            this.debouncedGetTemplatesVariables.cancel();
            this.debouncedGetSignablesVariables.cancel();
            window.removeEventListener('resize', this.resizePageContainer);
            window.removeEventListener('mousemove', this.resizeResizer);
            window.removeEventListener('mouseup', this.stopResizerResize);

            if (this.pdfViewer) {
                this.pdfViewer.eventBus.off('pagesloaded', this.resizePageContainer);
                this.pdfViewer.eventBus.off('pagerendered', this.pageRendered);
            }

            this.currentResizer = null;

            this.usedMarkers.addedOptions = [];
        },

        methods: {
            showAddParaphModal(variable) {
                this.addParaphVariable = variable;
                this.addParaphPages = 'all';
                this.addParaphPagesCustom = null;
            },

            addParaphOnPages() {
                const variable = this.addParaphVariable;

                if (!variable) {
                    return;
                }

                const pages = this.getParaphPages();

                const qrHeight = 32;
                const signersHeight = this.signers.length * 15;

                let minHeight = Math.max(
                    signersHeight,
                    qrHeight
                );

                minHeight += 10;

                if (this.pdfViewer.currentScale >= 1) {
                    minHeight *= this.pdfViewer.currentScale;
                } else {
                    minHeight /= this.pdfViewer.currentScale;
                }

                for (const page of pages) {
                    const pageElement = this.$refs.pageContainer.querySelector('[data-page-number="' + page + '"]');

                    if (!pageElement) {
                        continue;
                    }

                    const pageHeight = pageElement.getBoundingClientRect().height / this.pdfViewer.currentScale;

                    const y = pageHeight - minHeight - variable.options.defaultHeight;

                    let x = 10;

                    while (this.overlapsOtherParaph(page, x, y, variable.options.defaultWidth, variable.options.defaultHeight)) {
                        x += variable.options.defaultWidth + 2;
                    }

                    this.addVariable({
                        x,
                        y,
                        page
                    }, variable.type, variable.value, variable.options);
                }

                this.addParaphVariable = null;
            },

            overlapsOtherParaph(page, x, y, width, height) {
                return (this.localValue?.variables || []).some(variable => {
                    if (variable.page !== page) {
                        return false;
                    }

                    const variableX = variable.x;
                    const variableY = variable.y;
                    const variableWidth = variable.width;
                    const variableHeight = variable.height;


                    // no horizontal overlap
                    if (x >= (variableX + variableWidth) || variableX >= (x + width)) {
                        return false;
                    }

                    // no vertical overlap
                    if (y >= (variableY + variableHeight) || variableY >= (y + height)) {
                        return false;
                    }

                    return true;
                });
            },

            getParaphPages() {
                const totalPages = this.$refs.pageContainer.querySelectorAll('[data-page-number]').length;

                switch (this.addParaphPages) {
                    case 'all':
                        return Array.from(Array(totalPages).keys()).map(page => page + 1);
                    case 'even':
                        return Array.from(Array(totalPages).keys()).map(page => page + 1).filter(page => page % 2 === 0);
                    case 'odd':
                        return Array.from(Array(totalPages).keys()).map(page => page + 1).filter(page => page % 2 === 1);
                    case 'custom': {
                        if (!this.addParaphPagesCustom) {
                            return [];
                        }

                        const parts = this.addParaphPagesCustom.split(',');

                        return parts.reduce((pages, part) => {
                            const {min, max} = this.getPagesMinMaxFromPart(part);

                            if (isNaN(min) || isNaN(max)) {
                                return pages;
                            }

                            // Skip page more than the total pages
                            if (min === max && min > totalPages) {
                                return pages;
                            }

                            const minPage = Math.max(1, Math.min(totalPages, min));
                            const maxPage = Math.min(totalPages, Math.max(1, max));

                            if (minPage > maxPage) {
                                return pages;
                            }

                            for (let page = minPage; page <= maxPage; page++) {
                                if (pages.indexOf(page) === -1) {
                                    pages.push(page);
                                }
                            }

                            return pages;
                        }, []);
                    }
                }

                return [];
            },

            getPagesMinMaxFromPart(part) {
                part = String(part);

                if (!part.length) {
                    return {
                        min: null,
                        max: null
                    };
                }

                const dashIndex = part.indexOf('-');

                if (dashIndex === -1) {
                    return {
                        min: Number(part),
                        max: Number(part)
                    };
                }

                const min = part.substring(0, dashIndex);
                const max = part.substring(dashIndex + 1, part.length).replace('-', '');

                return {
                    min: Number(min),
                    max: Number(max)
                };
            },

            variableDateChanged() {
                if (!this.currentSelectedVariable) {
                    return;
                }

                this.currentSelectedVariable.value = this.variableDatePickerComponentValue;
                this.save();
            },

            addVariable({x, y, page}, type, value, options) {
                const variables = this.localValue?.variables || [];

                variables.push({
                    x,
                    y,
                    width: options?.defaultWidth || 150,
                    height: options?.defaultHeight || 30,
                    page,
                    type,
                    value,
                    options
                });

                this.localValue.variables = variables;

                this.save();
            },

            removeVariable(variable) {
                this.removeDateTimePicker();

                this.localValue.variables.splice(this.localValue.variables.indexOf(variable), 1);

                this.save();
            },

            save(rerender = true) {
                if (rerender) {
                    this.updateVariableElements();
                }

                this.$emit('input', this.localValue);
                this.$emit('change', this.localValue);
            },

            updateVariableElements() {
                if (!this.pdfViewer) {
                    return;
                }

                this.pdfViewer.container.querySelectorAll('.variable-canvas').forEach((element) => element.remove());

                window.removeEventListener('mousemove', this.resizeResizer);
                window.removeEventListener('mouseup', this.stopResizerResize);

                for (const variable of this.localValue.variables) {
                    const canvasWrapperElement = this.pdfViewer.container.querySelector('[data-page-number="' + variable.page + '"] .canvasWrapper');

                    if (!canvasWrapperElement) {
                        continue;
                    }

                    const variableElement = document.createElement('div');
                    const fontSize = this.getVariableFontSize(variable);

                    variableElement.variable = variable;

                    variableElement.classList.add('variable-canvas');
                    variableElement.style.top = (variable.y * this.pdfViewer.currentScale) + 'px';
                    variableElement.style.left = (variable.x * this.pdfViewer.currentScale) + 'px';
                    variableElement.style.width = (variable.width * this.pdfViewer.currentScale) + 'px';
                    variableElement.style.height = (variable.height * this.pdfViewer.currentScale) + 'px';
                    variableElement.style.fontSize = fontSize + 'px';

                    variableElement.draggable = true;

                    variableElement.ondragstart = (event) => {
                        this.removeDateTimePicker();

                        variableElement.startX = event.clientX;
                        variableElement.startY = event.clientY;

                        event.dataTransfer.setData('text', JSON.stringify({
                            variable,
                            action: 'move'
                        }));
                    };

                    variableElement.ondragend = (event) => {
                        variable.x = variable.x + ((event.clientX - variableElement.startX) / this.pdfViewer.currentScale);
                        variable.y = variable.y + ((event.clientY - variableElement.startY) / this.pdfViewer.currentScale);

                        this.save();
                    };

                    const resizersElement = document.createElement('div');
                    resizersElement.classList.add('resizers');

                    for (const position of ['top-left', 'top-right', 'bottom-left', 'bottom-right']) {
                        const resizerElement = document.createElement('div');
                        resizerElement.classList.add('resizer');
                        resizerElement.classList.add(position);

                        resizersElement.appendChild(resizerElement);
                    }

                    const removeVariableElement = document.createElement('span');
                    removeVariableElement.innerHTML = '&times;';
                    removeVariableElement.classList.add('variable-remove');
                    removeVariableElement.style.top = Math.max(-20, -20 * this.pdfViewer.currentScale) + 'px';
                    removeVariableElement.style.right = Math.max(-20, -20 * this.pdfViewer.currentScale) + 'px';
                    removeVariableElement.style.fontSize = Math.max(8, 27 * this.pdfViewer.currentScale) + 'px';

                    removeVariableElement.onclick = () => {
                        this.removeVariable(variable);
                    };

                    const dragVariableElement = document.createElement('span');
                    dragVariableElement.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M278.6 9.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8h32v96H128V192c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9l-64 64c-12.5 12.5-12.5 32.8 0 45.3l64 64c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V288h96v96H192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8H288V288h96v32c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l64-64c12.5-12.5 12.5-32.8 0-45.3l-64-64c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6v32H288V128h32c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-64-64z"/></svg>';
                    dragVariableElement.classList.add('variable-move');
                    dragVariableElement.style.top = Math.max(-20, -20 * this.pdfViewer.currentScale) + 'px';
                    dragVariableElement.style.left = Math.max(-20, -20 * this.pdfViewer.currentScale) + 'px';

                    dragVariableElement.onclick = () => {
                        this.removeVariable(variable);
                    };

                    switch (variable.type) {
                        case 'generated':
                            variableElement.innerText = variable.value;
                            break;

                        case 'checkbox_checked': {
                            const checkedElement = document.createElement('span');
                            checkedElement.classList.add('variable-checkbox-checked-input');

                            variableElement.appendChild(checkedElement);

                            break;
                        }

                        case 'text': {
                            const textElement = document.createElement('textarea');
                            textElement.classList.add('variable-text-input');
                            textElement.placeholder = 'Typ een tekst';
                            textElement.innerHTML = variable.value || '';
                            textElement.style.fontSize = fontSize + 'px';

                            textElement.onchange = (event) => {
                                variable.value = event.target.value;
                                this.save();
                            };

                            textElement.ondrop = (event) => {
                                event.preventDefault();
                            };

                            variableElement.appendChild(textElement);

                            break;
                        }

                        case 'date': {
                            const dateElement = document.createElement('div');
                            dateElement.classList.add('variable-date-input');
                            dateElement.innerText = variable.value ? moment(variable.value).format(variable.options.format) : '';
                            dateElement.style.fontSize = fontSize + 'px';

                            dateElement.onclick = (event) => {
                                event.stopPropagation();
                                this.renderDateTimePicker(event.target, variable);
                            };

                            variableElement.appendChild(dateElement);

                            break;
                        }
                    }

                    variableElement.appendChild(resizersElement);
                    variableElement.appendChild(removeVariableElement);
                    variableElement.appendChild(dragVariableElement);

                    canvasWrapperElement.appendChild(variableElement);

                    this.makeResizableDiv(canvasWrapperElement, variableElement, variable);
                }
            },

            getVariableFontSize(variable) {
                if (variable.type === 'generated' && variable.value.indexOf('.signature') !== -1) {
                    return Math.max(8, 14 * this.pdfViewer.currentScale);
                }

                const height = variable.height * this.pdfViewer.currentScale;
                const width = variable.width * this.pdfViewer.currentScale;

                let textFontSize = height / 2;
                const lines = (variable.value || 'Typ een tekst').split('\n').map((line) => {
                    return line.replace(/\n|\r/, '');
                });

                const textWidth = lines.reduce((textWidth, line) => {
                    const lineWidth = (this.getTextWidth(line, textFontSize));

                    if (lineWidth > textWidth) {
                        return lineWidth;
                    }

                    return textWidth;
                }, 0);

                // Check if the text fits
                if (textWidth >= width) {
                    textFontSize = textFontSize * (width / textWidth) - 2;
                }

                // Font size 8 is the minimum
                if (textFontSize <= 8) {
                    textFontSize = 8;
                }

                return textFontSize;
            },

            getTextWidth(text, fontSize) {
                const measurer = document.createElement('span');
                measurer.style.setProperty('all', 'revert', 'important');
                measurer.style.setProperty('position', 'position', 'important');
                measurer.style.setProperty('visibility', 'hidden', 'important');
                measurer.style.setProperty('width', 'max-content', 'important');
                measurer.style.setProperty('font-family', 'Helvetica', 'important');
                measurer.style.setProperty('font-size', fontSize + 'px', 'important');
                measurer.innerText = text;

                document.body.appendChild(measurer);
                const {width} = measurer.getBoundingClientRect();
                document.body.removeChild(measurer);
                return width;
            },

            renderDateTimePicker(element, variable) {
                const pageContainerBoundaries = this.$refs.allContainer.getBoundingClientRect();
                const boundaries = element.getBoundingClientRect();

                this.$refs.datePickerComponent.style.left = (boundaries.left - pageContainerBoundaries.left + window.scrollX) + 'px';
                this.$refs.datePickerComponent.style.top = (boundaries.top - pageContainerBoundaries.top + window.scrollY) + 'px';

                this.$refs.datePickerComponent.style.width = boundaries.width + 'px';
                this.$refs.datePickerComponent.style.height = boundaries.height + 'px';

                this.variableDatePickerComponentFormat = variable.options.format;
                this.variableDatePickerComponentValue = variable.value;
                this.currentSelectedVariable = variable;

                this.$refs.datePickerVueComponent.pickerPosition = null;
                this.$refs.datePickerVueComponent.toggleDatePicker(true);
            },

            removeDateTimePicker() {
                if (!this.currentSelectedVariable) {
                    return;
                }

                this.currentSelectedVariable = null;
                this.$refs.datePickerVueComponent.closePicker();
            },

            resizeResizer(e) {
                this.removeDateTimePicker();

                const currentResizer = this.currentResizer;

                if (!currentResizer) {
                    return;
                }

                const element = currentResizer.resizerElement;

                if (!element) {
                    return;
                }

                const minimumWidth = (currentResizer?.variable?.options?.minWidth || 150) * this.pdfViewer.currentScale;
                const minimumHeight = (currentResizer?.variable?.options?.minHeight || 30) * this.pdfViewer.currentScale;

                const originalMouseX = element.originalMouseX;
                const originalMouseY = element.originalMouseY;

                const originalX = element.originalX;
                const originalY = element.originalY;

                const x = e.clientX;
                const y = e.clientY;

                const keepRatio = currentResizer?.variable?.options?.keepRatio || false;
                const resizeSize = e.clientX - originalMouseX;

                if (currentResizer.classList.contains('bottom-right')) {
                    const newWidth = keepRatio ? resizeSize : (x - originalMouseX);
                    const newHeight = keepRatio ? resizeSize : (y - originalMouseY);

                    const width = element.originalWidth + newWidth;
                    const height = element.originalHeight + newHeight;

                    if (width > minimumWidth) {
                        element.style.width = width + 'px';
                    }

                    if (height > minimumHeight) {
                        element.style.height = height + 'px';
                    }
                } else if (currentResizer.classList.contains('bottom-left')) {
                    const newWidth = keepRatio ? resizeSize : (x - originalMouseX);
                    const newHeight = keepRatio ? -resizeSize : (y - originalMouseY);

                    const height = element.originalHeight + newHeight;
                    const width = element.originalWidth - newWidth;

                    if (height > minimumHeight) {
                        element.style.height = height + 'px';
                    }

                    if (width > minimumWidth) {
                        element.style.width = width + 'px';

                        if (!keepRatio) {
                            element.style.left = originalX + (x - originalMouseX) + 'px';
                        }
                    }
                } else if (currentResizer.classList.contains('top-right')) {
                    const newWidth = keepRatio ? resizeSize : (x - originalMouseX);
                    const newHeight = keepRatio ? -resizeSize : (y - originalMouseY);

                    const width = element.originalWidth + newWidth;
                    const height = element.originalHeight - newHeight;

                    if (width > minimumWidth) {
                        element.style.width = width + 'px';
                    }

                    if (height > minimumHeight) {
                        element.style.height = height + 'px';

                        if (!keepRatio) {
                            element.style.top = originalY + (y - originalMouseY) + 'px';
                        }
                    }
                } else {
                    const newWidth = keepRatio ? -resizeSize : (x - originalMouseX);
                    const newHeight = keepRatio ? -resizeSize : (y - originalMouseY);

                    const width = element.originalWidth - newWidth;
                    const height = element.originalHeight - newHeight;

                    if (width > minimumWidth) {
                        element.style.width = width + 'px';

                        if (!keepRatio) {
                            element.style.left = originalX + (x - originalMouseX) + 'px';
                        }
                    }

                    if (height > minimumHeight) {
                        element.style.height = height + 'px';

                        if (!keepRatio) {
                            element.style.top = originalY + (y - originalMouseY) + 'px';
                        }
                    }
                }
            },

            stopResizerResize() {
                if (!this.currentResizer) {
                    return;
                }

                const element = this.currentResizer.resizerElement;
                const variable = this.currentResizer.variable;

                element.style.opacity = 1;

                variable.width = Number(element.style.width.replace('px', '')) / this.pdfViewer.currentScale;
                variable.height = Number(element.style.height.replace('px', '')) / this.pdfViewer.currentScale;
                variable.x = Number(element.style.left.replace('px', '')) / this.pdfViewer.currentScale;
                variable.y = Number(element.style.top.replace('px', '')) / this.pdfViewer.currentScale;

                window.removeEventListener('mousemove', this.resizeResizer);
                this.currentResizer = null;

                this.save();
            },

            makeResizableDiv(canvasWrapperElement, element, variable) {
                const resizers = element.querySelectorAll('.resizer');

                element.originalWidth = 0;
                element.originalHeight = 0;
                element.originalX = 0;
                element.originalY = 0;
                element.originalMouseX = 0;
                element.originalMouseY = 0;

                element.canvas = canvasWrapperElement.getElementsByTagName('canvas')[0];

                for (let i = 0; i < resizers.length; i++) {
                    const currentResizer = resizers[i];

                    currentResizer.resizerElement = element;
                    currentResizer.variable = variable;

                    currentResizer.addEventListener('mousedown', (e) => {
                        e.preventDefault();

                        const rect = element.canvas.getBoundingClientRect();

                        element.originalWidth = Number(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
                        element.originalHeight = Number(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
                        element.originalX = element.getBoundingClientRect().left - rect.left;
                        element.originalY = element.getBoundingClientRect().top - rect.top;
                        element.originalMouseX = e.clientX;
                        element.originalMouseY = e.clientY;

                        element.style.opacity = 0.8;

                        this.currentResizer = currentResizer;

                        window.addEventListener('mousemove', this.resizeResizer);
                        window.addEventListener('mouseup', this.stopResizerResize);
                    });
                }
            },

            startDraggingSignerVariable(event, variable) {
                event.dataTransfer.setData('text', JSON.stringify({
                    variable,
                    action: 'add',
                    startX: event.clientX,
                    startY: event.clientY
                }));
            },

            getSignerVariables(index, signer) {
                const signerIndex = index + 1;
                const variables = [
                    {
                        order: 3,
                        name: 'Naam',
                        value: 'signer.' + signerIndex + '.name',
                        type: 'generated',
                        renderType: 'name',
                    },
                    {
                        order: 5,
                        name: 'Ondertekendatum',
                        value: 'signer.' + signerIndex + '.date',
                        type: 'generated',
                        renderType: 'date',
                    }
                ];

                if (signer.verifications.some(verification => verification.type === 5/*signature*/)) {
                    variables.push({
                        order: 2,
                        name: 'Handtekening',
                        value: 'signer.' + signerIndex + '.signature',
                        type: 'generated',
                        renderType: 'signature',
                    });
                }

                if (signer.verifications.some(verification => verification.type === 6/*paraph*/)) {
                    variables.push({
                        order: 4,
                        name: 'Paraaf',
                        value: 'signer.' + signerIndex + '.paraph',
                        type: 'generated',
                        renderType: 'paraph',
                        options: {
                            minWidth: 50,
                            minHeight: 50,
                            defaultWidth: 50,
                            defaultHeight: 50,
                            keepRatio: true
                        }
                    });
                }

                return variables.sort((a, b) => a.order - b.order);
            },

            renderVariablesPdf(uploaded = false) {
                this.uploaded = uploaded;
                this.showVariablesModal = true;

                // Remove previous events
                if (this.pdfViewer) {
                    this.pdfViewer.eventBus.off('pagesloaded', this.resizePageContainer);
                    this.pdfViewer.eventBus.off('pagerendered', this.pageRendered);
                }

                this.$nextTick(async () => {
                    let container = this.$refs.pageContainer;

                    this.pdfViewer = new PDFViewer({
                        container: container,
                        scale: 'page-width',
                        renderInteractiveForms: false, // no forms
                        textLayerMode: 0 // disable
                    });

                    let pdf = await pdfjsLib.getDocument(this.signable.src);

                    this.pdfViewer.eventBus.on('pagesloaded', this.resizePageContainer);
                    this.pdfViewer.eventBus.on('pagerendered', this.pageRendered);

                    this.pdfViewer.setDocument(pdf);

                    this.signable.showModal = false;
                });
            },

            pageRendered(renderEvent) {
                renderEvent.source.canvas.removeEventListener('drop', this.canvasDropEvent);
                renderEvent.source.canvas.removeEventListener('dragover', this.canvasDragOverEvent);

                renderEvent.source.canvas.addEventListener('drop', this.canvasDropEvent);
                renderEvent.source.canvas.addEventListener('dragover', this.canvasDragOverEvent);

                this.$nextTick(() => {
                    this.updateVariableElements();
                });
            },

            canvasDragOverEvent(event) {
                event.preventDefault();
                return false;
            },

            canvasDropEvent(canvasEvent) {
                canvasEvent.stopPropagation();
                canvasEvent.preventDefault();

                const dragData = canvasEvent.dataTransfer.getData('text');

                if (!dragData) {
                    return;
                }

                const {variable, action} = JSON.parse(dragData);
                const page = Number(canvasEvent.target.parentElement.parentElement.getAttribute('data-page-number'));
                const rect = canvasEvent.target.getBoundingClientRect();

                const x = (canvasEvent.clientX - rect.left) / this.pdfViewer.currentScale;
                const y = (canvasEvent.clientY - rect.top) / this.pdfViewer.currentScale;

                if (action === 'add') {
                    this.addVariable({
                        x,
                        y,
                        page
                    }, variable.type, variable.value, variable.options);
                } else if (action === 'move') {
                    if (variable.page === page) {
                        return;
                    }

                    const localVariable = this.localValue.variables.find(localVariable => {
                        return (
                            localVariable.x === variable.x &&
                            localVariable.y === variable.y &&
                            localVariable.width === variable.width &&
                            localVariable.height === variable.height &&
                            localVariable.page === variable.page
                        );
                    });

                    if (!localVariable) {
                        return;
                    }

                    localVariable.x = x;
                    localVariable.y = y;
                    localVariable.page = page;

                    this.save();
                }
            },

            resizePageContainer() {
                if (!this.pdfViewer) {
                    return;
                }

                this.pdfViewer.currentScaleValue = 'page-fit';
            },

            async getTemplatesVariables(searchQuery) {
                try {
                    const response = await this.$http.get(this.$root.api_url + '/api/templates/variables', {
                        params: {
                            search: searchQuery
                        }
                    });
                    this.usedMarkers.templates = response.data;
                } catch (exception) {
                    console.error(exception);
                }
            },

            async getSignablesVariables(searchQuery) {
                try {
                    const response = await this.$http.get(this.$root.api_url + '/api/signrequests/variables', {
                        params: {
                            search: searchQuery
                        }
                    });
                    this.usedMarkers.signables = response.data;
                } catch (exception) {
                    console.error(exception);
                }
            },

            handleSelectVariable(option) {
                if (!option) return;

                // Handle an array of variables
                let variables = Array.isArray(option.variables) ? option.variables : [option];

                variables.forEach(variableOption => {
                    const maxPages = this.$refs.pageContainer.querySelectorAll('[data-page-number]').length;

                    const variable = {
                        ...variableOption,
                        page: ((variableOption.page > maxPages) ? maxPages : variableOption.page || 1).toString() // Default to page 1 if not specified
                    };

                    this.usedMarkers.addedOptions.push({
                        id: option.id,
                        name: option.name,
                        ...variable
                    });

                    this.localValue.variables.push(variable);
                });

                this.save();
            }

        }
    };

</script>

<style>

    .variables-signers {
        max-height: 50vh;
        overflow: auto;
    }

    .signable-variables .all-container {
        width: 100%;
        max-height: 70vh;
        position: relative;
    }

    .signable-variables .page-container {
        margin: auto;
        width: 100%;
        background-color: #c1c1c1;
        max-height: 70vh;
        overflow: auto;
        position: relative;
    }

    .signable-variables div.page {
        display: inline-block;
    }

    .variables-custom,
    .signable-variables .variable-signer {
        text-align: left;
    }

    .signable-variables .annotationLayer {
        display: none;
    }

    .signable-variables .variable-box {
        background: #fff;
        border-radius: 5px;
        padding: 5px;
        text-align: left;
        margin-bottom: 5px;
        cursor: grab;
        box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        display: flex;
        align-items: center;
    }

    .signable-variables .variable-box .variable-value {
        display: block;
        line-height: 8px;
        margin-top: -5px;
        margin-bottom: 5px;
        color: #939393;
    }

    .signable-variables .canvasWrapper {
        position: relative;
    }

    .signable-variables .variable-canvas {
        position: absolute;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.8);
        text-align: left;
        padding: 1px 5px;
    }

    .signable-variables .variable-canvas .variable-move,
    .signable-variables .variable-canvas .variable-remove {
        position: absolute;
        color: #164084;
        width: 15px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
    }

    .signable-variables .variable-canvas .variable-move svg {
        fill: #164084;
    }

    .signable-variables .variable-canvas .resizers {
        width: 100%;
        height: 100%;
        border: 2px solid rgba(0, 65, 136, 0.8);
        box-sizing: border-box;
        position: absolute;
        top: 0;
        left: 0;
    }

    .signable-variables .variable-canvas .resizers .resizer {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.8);
        border: 2px solid rgba(0, 65, 136, 0.8);
        position: absolute;
        z-index: 2;
    }

    .signable-variables .variable-canvas .resizers .resizer.top-left {
        left: -5px;
        top: -5px;
        cursor: nwse-resize;
    }

    .signable-variables .variable-canvas .resizers .resizer.top-right {
        right: -5px;
        top: -5px;
        cursor: nesw-resize;
    }

    .signable-variables .variable-canvas .resizers .resizer.bottom-left {
        left: -5px;
        bottom: -5px;
        cursor: nesw-resize;
    }

    .signable-variables .variable-canvas .resizers .resizer.bottom-right {
        right: -5px;
        bottom: -5px;
        cursor: nwse-resize;
    }

    .signable-variables .variable-canvas .variable-checkbox-checked-input,
    .signable-variables .variable-canvas .variable-date-input,
    .signable-variables .variable-canvas .variable-text-input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        resize: none;
        border: none;
        outline: none;
        background-color: transparent;
        padding: 5px;
        overflow: hidden;
        z-index: 1;
        white-space: nowrap;
    }

    .signable-variables .variable-canvas .variable-checkbox-checked-input {
        border-radius: 100%;
        background-color: #000;
    }

    .variable-date-picker {
        width: 100px;
        height: 100px;
        position: absolute;
        z-index: 100000;
        pointer-events: none;
    }

    .variable-date-picker .datetimepicker {
        pointer-events: all;
    }

    .variable-date-picker .date-time-picker {
        display: flex;
        width: 100%;
        height: 100%;
    }

    .variable-date-picker .field,
    .variable-date-picker .field-input {
        display: none !important;
    }

    .info-text {
        line-height: 18px;
        text-align: left;
    }

    .text-left {
        text-align: left;
    }

    .multiselect__content-wrapper {
        position: absolute ; /* Ensure it's absolutely positioned */
        z-index: 1050 !important; /* Ensure it appears above other elements */
        overflow: visible !important; /* Enable vertical scrolling */
        max-height: 300px; /* Restrict maximum vertical height */
        white-space: nowrap; /* Prevent text wrapping */
        background-color: #ffffff; /* Dropdown background color */
        min-width: 100%; /* Ensure dropdown is at least as wide as its trigger */
    }

    .multiselect__content {
        overflow-y: scroll !important;
        background: #ffffff;
        max-height: 300px;
        border: 1px solid #959595; /* Add a border for better visibility */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for dropdown */
        border-radius: 4px; /* Rounded corners */
    }

    /* This fixes the multiselect, it shows the border with 0px width */
    .multiselect__input {
        border: none !important;
        box-shadow: none !important;
        transition: border 0.3s, box-shadow 0.3s; /* Smooth transition */
    }

    .multiselect__input:focus {
        border: 1px solid #007bff !important; /* Add border on focus */
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5) !important; /* Add shadow on focus */
    }

    .multiselect__input:hover {
        border: 1px solid #ced4da !important; /* Subtle border on hover */
    }
</style>