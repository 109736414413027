import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Signings from "@/views/Signings";
import NewSigning from "@/views/NewSigning";
import ShowSigning from "@/views/ShowSigning";
import AccountSettings from "@/views/AccountSettings";
import CompanySettings from "@/views/CompanySettings";
import EmailTemplates from "@/views/EmailTemplates";
import Credits from "@/views/Credits";
import Invoices from "@/views/Invoices";
import Api from "../views/Api";
import Webhook from "../views/Webhook";
import Users from "../views/Users";
import AllUsers from "../views/admin/AllUsers";
import CreditsBoughtThanks from "../views/CreditsBoughtThanks";
import PasswordReminder from "../views/PasswordReminder";
import PasswordReset from "../views/PasswordReset";
import PasswordChange from "../views/PasswordChange";
import Contact from "../views/Contact";
import AllInvoices from '../views/admin/AllInvoices';
import AllPaymentPlans from '../views/admin/AllPaymentPlans.vue';
import Templates from '../views/Templates.vue';

const routes = [
    {
        path: '/',
        name: 'Signings',
        component: Signings
    },
    {
        path: '/nieuwe-ondertekening',
        name: 'NewSigning',
        component: NewSigning
    },
    {
        path: '/ondertekening/:id',
        name: 'ShowSigning',
        component: ShowSigning
    },
    {
        path: '/instellingen/account',
        name: 'AccountSettings',
        component: AccountSettings
    },
    {
        path: '/instellingen/bedrijf',
        name: 'CompanySettings',
        component: CompanySettings,
    },
    {
        path: '/instellingen/account/wachtwoord-veranderen',
        name: 'PasswordChange',
        component: PasswordChange,
    },
    {
        path: '/instellingen/credits',
        name: 'Credits',
        component: Credits
    },
    {
        path: '/instellingen/credits/gekocht',
        name: 'CreditsBought',
        component: CreditsBoughtThanks,
        meta: {
            guest: true,
            auth: true
        }
    },
    {
        path: '/instellingen/facturen',
        name: 'Invoices',
        component: Invoices
    },
    {
        path: '/instellingen/emailtemplates',
        name: 'EmailTemplates',
        component: EmailTemplates,
        meta: {
            feature: 'email_templates'
        }
    },
    {
        path: '/instellingen/api',
        name: 'Api',
        component: Api,
        meta: {
            feature: 'api'
        }
    },
    {
        path: '/instellingen/webhooks',
        name: 'Webhooks',
        component: Webhook,
        meta: {
            feature: 'webhooks'
        }
    },
    {
        path: '/instellingen/gebruikers',
        name: 'Users',
        component: Users
    },
    {
        path: '/templates',
        name: 'Templates',
        component: Templates,
        meta: {
            feature: 'templates'
        }
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            guest: true
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            guest: true
        }
    },
    {
        path: '/wachtwoord-vergeten',
        name: 'PasswordReminder',
        component: PasswordReminder,
        meta: {
            guest: true
        }
    },
    {
        path: '/wachtwoord-instellen/:token',
        name: 'PasswordReset',
        component: PasswordReset,
        meta: {
            guest: true
        }
    },
    /** admin routes */
    {
        path: '/all-users',
        name: 'AllUsers',
        component: AllUsers,
    },

    {
        path: '/all-invoices',
        name: 'AllInvoices',
        component: AllInvoices,
    },

    {
        path: '/all-payment-plans',
        name: 'AllPaymentPlans',
        component: AllPaymentPlans,
    },

    {
        path: '*',
        beforeEnter: (to, from, next) => {
            return next('/');
        },
        meta: {
            guest: true
        }
    }
]


export default routes
