var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid d-flex flex-column "},[_c('div',{staticClass:"row justify-content-center fill-width"},[_c('div',{staticClass:"col-md-6 col-lg-5 col-xl-4 py-6 py-md-0 mt-5"},[_c('div',{staticClass:"card",staticStyle:{"max-width":"600px","margin":"0 auto"}},[_c('div',{staticClass:"card-body"},[_c('div',[_vm._m(0),_c('span',{staticClass:"clearfix"}),_c('ValidationObserver',{ref:"login",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{ref:"loginForm",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[(!_vm.two_factor_hash)?[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Emailadres: "),_c('sup',[_vm._v("*")])]),_c('div',{},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.email),expression:"request.email"}],staticClass:"form-control fusion-form-input",attrs:{"disabled":_vm.loading,"type":"email","id":"email","name":"email","placeholder":""},domProps:{"value":(_vm.request.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.request, "email", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Wachtwoord: "),_c('sup',[_vm._v("*")])]),_c('div',{},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.password),expression:"request.password"}],staticClass:"form-control",attrs:{"disabled":_vm.loading,"type":"password","placeholder":"","name":"password"},domProps:{"value":(_vm.request.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.request, "password", $event.target.value)}}}),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"mt-1"},[_c('button',{staticClass:"btn btn-primary btn-caps",attrs:{"type":"submit","disabled":invalid || _vm.loading,"id":"login-button"}},[_vm._v(" Inloggen ")])])]:_vm._e(),(_vm.two_factor_hash)?[_c('div',{staticClass:"form-group"},[_c('p',{staticClass:"lh-sm"},[_vm._v("Wij hebben je een tweestaps code per mail verstuurd, vul deze alsjeblieft hieronder in om door te gaan met inloggen:")])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"form-control-label"},[_vm._v("Code: "),_c('sup',[_vm._v("*")])]),_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"two-factor-code"},_vm._l((Array(5)),function(_,i){return _c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.request.two_factor_code[i]),expression:"request.two_factor_code[i]"}],key:i,staticClass:"form-control fusion-form-input",attrs:{"disabled":_vm.loading,"autofocus":i === 0,"type":"tel","name":"two_factor_code","maxlength":"1"},domProps:{"value":(_vm.request.two_factor_code[i])},on:{"paste":function($event){$event.preventDefault();return _vm.twoFactorPaste.apply(null, arguments)},"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.request.two_factor_code, i, $event.target.value)},_vm.twoFactorInput],"keyup":_vm.twoFactorKeyup}})}),0),_c('small',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group mt-3"},[_c('a',{staticClass:"cursor-pointer",on:{"click":_vm.clearTwoFactor}},[_c('font-awesome-icon',{staticClass:"me-2",attrs:{"icon":"chevron-left"}}),_c('span',[_vm._v("Terug naar inloggen")])],1)])]:_vm._e(),_c('div',{staticClass:"text-center form-group mt-3"},[_c('small',[_c('router-link',{attrs:{"to":"/wachtwoord-vergeten","id":"login-password-reset-link"}},[_vm._v("Wachtwoord vergeten")])],1),_vm._v(" | "),_c('small',[_c('router-link',{attrs:{"to":"/register","id":"login-register-link"}},[_vm._v("Registreer")])],1)]),(_vm.errormessage)?_c('div',{staticClass:"alert alert-danger mt-5"},[_vm._v(_vm._s(_vm.errormessage))]):_vm._e()],2)]}}])})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-5 mt-5 text-center"},[_c('h3',{staticClass:"h3-green",staticStyle:{"font-size":"40px"}},[_vm._v("Inloggen")]),_c('p',[_c('strong',[_vm._v("Vul hieronder je gegevens in om te in loggen.")])])])}]

export { render, staticRenderFns }