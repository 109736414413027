<template>

    <div class="home mt-5">

        <fullscreen-loading v-if="loading"></fullscreen-loading>

        <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
            <div class="row">
                <div class="col-12">
                    <div class="float-start fusion-title">
                        <h3 class="h3-green" style="font-size:50px;">Ondertekening</h3>
                    </div>
                    <div class="float-end" v-if="signrequest.id">
                        <div v-if="signrequest.state_id === 1/*created*/">
                            <button class="btn btn-success"
                                    :disabled="sendSignRequestDisabled"
                                    @click="markSignRequestAsReady">
                                <font-awesome-icon icon="paper-plane"/>
                                Ondertekening versturen
                            </button>
                            <br>
                        </div>
                        <div v-else-if="signrequest.state_id === 3/*in progress*/">
                            <button class="btn btn-danger " :disabled="signrequest.state_id === 8/*cancelled*/"
                                    @click="show_cancel_modal = true">
                                <font-awesome-icon icon="trash"/>
                                Ondertekening annuleren
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row"
             v-if="company.credits < signrequest.credits_cost && signrequest.state_id === 1 && company.current_payment_plan.id === 1/*prepaid*/">
            <div class="col-12">
                <div class="alert alert-warning">
                    U heeft niet voldoende credits om deze ondertekening te versturen. Klik
                    <router-link :to="`/instellingen/credits`">hier</router-link>
                    om nieuwe credits aan te schaffen
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-7">
                <div class="card signcard p-10">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-12"><h2 class="title-heading-left mb-3">Ondertekening</h2></div>
                        </div>

                        <div class="row mb-1">
                            <div class="col-6">
                                Status:
                            </div>
                            <div class="col-6">
                                <span class="badge " v-if="signrequest.state_id" :class="getStateClassFromID(signrequest.state_id)">
                                    <span v-if="signrequest.state_id === 5">Getekend</span>
                                    <span v-else>{{ getStateNameFromID(signrequest.state_id) }}</span>
                                </span>
                            </div>
                        </div>

                        <div class="row mb-1">
                            <div class="col-6">
                                Credits:
                            </div>
                            <div class="col-6">
                                {{ signrequest.credits_cost }}
                            </div>
                        </div>

                        <div class="row" v-if="signrequest.emails && signrequest.emails.length">
                            <div class="col-6">
                                Extra ontvanger getekende documenten:
                            </div>
                            <div class="col-6">
                                <span>{{ signrequest.emails.join(', ') }}</span>
                            </div>
                        </div>

                        <div class="row" v-if="signrequest.state_id === 5/* approved */">
                            <div class="col-6">
                                Overzicht:
                            </div>
                            <div class="col-6">
                                <a :download="'Signly ondertekening overzicht ' + signrequest.id + ' ' + signrequest.date + '.pdf'"
                                   :href="`/api/sign-requests/${signrequest.id}/receipt/download?token=` + token"
                                   class="btn btn-primary btn-sm btn-caps me-2"
                                >
                                    <font-awesome-icon icon="download"></font-awesome-icon>
                                    Download
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-5">
                <div class="card signcard p-10">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-12"><h2 class="title-heading-left mb-3">Audit Overzicht</h2></div>
                        </div>
                        <div class="row mb-1">
                            <div class="col-6">
                                Aangemaakt op:
                            </div>
                            <div class="col-6" v-if="signrequest.created_at">
                                {{ signrequest.created_at | moment('DD-MM-YYYY HH:mm') }}
                            </div>
                        </div>
                        <div class="row mb-1" v-if="!signrequest.completed_at">
                            <div class="col-6">
                                Vervalt op:
                            </div>
                            <div class="col-6" v-if="signrequest.expires_at">
                                {{ signrequest.expires_at | moment('DD-MM-YYYY HH:mm') }}
                            </div>
                        </div>

                        <div v-if="signrequest.completed_at">
                            <div class="row mb-1" v-for="signer in signrequest.signers" :key="signer.id">
                                <div class="col-6">
                                    {{ signer.firstname }} {{ signer.lastname }}
                                </div>
                                <div class="col-6" v-if="signrequest.completed_at">
                                    {{ signrequest.completed_at | moment('DD-MM-YYYY HH:mm') }}
                                </div>
                            </div>
                        </div>

                        <div class="row mb-1" v-if="signrequest.completed_at">
                            <div class="col-6">
                                Voltooid op:
                            </div>
                            <div class="col-6" v-if="signrequest.completed_at">
                                {{ signrequest.completed_at | moment('DD-MM-YYYY HH:mm') }}
                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </div>


        <div class="row mt-3">

            <div class="col-6">
                <div class="card signcard p-10">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12"><h2 class="title-heading-left mb-3">Ondertekenaars</h2></div>
                        </div>

                        <div class="alert alert-primary" v-if="signrequest.signers && signrequest.signers.length === 0 && !loading">
                            Geen ondertekenaars gevonden.
                        </div>

                        <div class="mb-5 entity_block" v-for="(signer) in signrequest.signers" :key="'signer' + signer.id">

                            <div class="row mb-1">
                                <div class="col-5">
                                    Naam:
                                </div>
                                <div class="col-7">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span>
                                            <LanguageFlag :locale="signer.locale" class="language-flag"/>
                                            {{ signer.firstname }} {{ signer.lastname }}
                                        </span>
                                        <span>
                                            <button class="btn btn-primary btn-sm"
                                                    style="width: 30px; height: 30px"
                                                    v-tooltip="'Bekijk audit overzicht van ondertekenaar'"
                                                    @click="auditTrailModal = { actions: signer.actions, name: signer.firstname + ' ' + signer.lastname }"
                                            >
                                                <font-awesome-icon icon="info"/>
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-5 d-flex align-items-center">
                                    Email:
                                </div>
                                <div class="col-7">
                                    <div class="input-group justify-content-between"
                                         v-if="signerCanBeEdited(signer) && signersBeingEdited.includes(signer.id)">
                                        <input
                                            class="form-control"
                                            type="email"
                                            @keydown="$event.key === 'Enter' ? updateSigner(signer) : null "
                                            v-model="signer.email"
                                        >
                                        <div class="input-group-append">
                                            <button
                                                class="btn btn-success h-100 btn-sm"
                                                @click="updateSigner(signer)"
                                            >
                                                <font-awesome-icon icon="save"/>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="justify-content-between d-flex">
                                        <a :href="'mailto:' + signer.email" v-if="!signersBeingEdited.includes(signer.id)">{{
                                                signer.email
                                            }}</a>
                                        <button
                                            class="btn btn-primary btn-sm d-flex justify-content-center align-items-center"
                                            style="width: 30px; height: 30px"
                                            v-if="signerCanBeEdited(signer) && !signersBeingEdited.includes(signer.id)"
                                            @click="signersBeingEdited.push(signer.id)"
                                        >
                                            <font-awesome-icon icon="pencil-alt"/>
                                        </button>
                                    </div>
                                </div>
                                <div class="col-12" v-if="signersBeingEdited.includes(signer.id)">
                                    <small class="text-primary">
                                        <i>
                                            Nieuwe email adressen zijn niet automatisch uitgenodigd voor ondertekenen. Verstuur daarom een handmatige herinnering na het aanpassen van het e-mail adres.
                                        </i>
                                    </small>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-5">
                                    Automatische herinnering op:
                                </div>
                                <div class="col-7 d-flex justify-content-between">
                                    {{ getReminderAfterDate(signer) }}
                                    <button style="width: 30px; height: 30px" class="btn btn-sm btn-primary"
                                            v-tooltip="'Totaal aantal automatische herinneringen: ' + signer.max_reminders + '.<br> Bekijk het audit overzicht (het blauwe informatieknopje bij de naam) voor details.'">
                                        <font-awesome-icon icon="info"/>
                                    </button>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-5">
                                    Handmatige herinneringen:
                                </div>
                                <div class="col-7 d-flex justify-content-between">
                                    {{ signer.manual_reminders_sent }}
                                    <button
                                        style="width: 30px; height: 30px"
                                        class="btn btn-success btn-sm"
                                        @click="sendManualReminder(signer)"
                                        v-tooltip="'Verstuur handmatige herinering'"
                                        v-if="signerCanBeEdited(signer)"
                                    >
                                        <font-awesome-icon icon="paper-plane"/>
                                    </button>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-5">
                                    Status:
                                </div>
                                <div class="col-7">
                                    <span class="badge " v-if="signer.state_id"
                                          :class="getStateClassFromID(signer.state_id)">{{ getStateNameFromID(signer.state_id) }} </span>
                                </div>
                            </div>
                            <div class="row mb-1">
                                <div class="col-5">
                                    Verificatiemethodes:
                                </div>
                                <div class="col-7">
                                    <span v-for="verification in signer.verification_methods" :key="verification.id"
                                          class="badge bg-secondary margin-right-3">{{
                                            getVerificationTypeFromNumber(verification.type)
                                        }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="card signcard p-10">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12"><h2 class="title-heading-left mb-3">Documenten</h2></div>
                        </div>

                        <div class="alert alert-primary" v-if="signrequest.signables && signrequest.signables.length === 0 && !loading">
                            Geen documenten gevonden.
                        </div>

                        <div class="row mb-5 entity_block" v-for="signable in signrequest.signables" :key="'signable' + signable.id">

                            <div class="col-12">
                                <div class="row mb-1">
                                    <div class="col-5">
                                        Documentnaam:
                                    </div>
                                    <div class="col-7">
                                        {{ signable.name }}
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-5">
                                        Status:
                                    </div>
                                    <div class="col-7">
                                        <span class="badge " v-if="signable.state_id" :class="getStateClassFromID(signable.state_id)">{{
                                                getStateNameFromID(signable.state_id)
                                            }} </span>
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-5">
                                        Aantal pagina's:
                                    </div>
                                    <div class="col-7">
                                        {{ signable.options.pages }}
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-5">
                                        Bestandsgrootte:
                                    </div>
                                    <div class="col-7">
                                        {{ $root.formatBytes(signable.options.filesize) }}
                                    </div>
                                </div>

                                <div class="row mb-1">
                                    <div class="col-5">
                                        Getekend op:
                                    </div>
                                    <div class="col-7">
                                        {{ signable.signed_at | moment('DD-MM-YYYY HH:mm') }} &nbsp;
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="col-5">
                                        &nbsp;
                                    </div>
                                    <div class="col-7">
                                        &nbsp;
                                        <!--                                        {{ signable.signed_at | moment('DD-MM-YYYY HH:mm') }}-->
                                    </div>
                                </div>
                            </div>

                            <div class="col-12" v-if="isSigned()">
                                <div class="clearfix"></div>
                                <div class="mb-2">
                                    <div v-if="signable.file_deleted_at" class="alert alert-info">
                                        Document is niet meer beschikbaar.
                                    </div>
                                    <div v-else>
                                        <button class="btn btn-primary btn-sm btn-caps me-2" @click="downloadPDF(signable)">
                                            <font-awesome-icon icon="download"></font-awesome-icon>
                                            Download
                                        </button>

                                        <button class="btn btn-primary btn-sm btn-caps" @click="previewPDF(signable)">
                                            <font-awesome-icon icon="download"></font-awesome-icon>
                                            Bekijk
                                        </button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>

            </div>


        </div>

        <div v-if="signrequest.custom_field_values.length > 0" class="row mt-3">
            <div class="col-5">
                <div class="card signcard p-10">
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-12"><h2 class="title-heading-left mb-3">Eigen velden</h2></div>
                        </div>
                        <div v-for="(custom_field_value, index) in signrequest.custom_field_values" class="row mb-1"
                             :key="'custom_field_values_' + index">
                            <div class="col-6">
                                {{ custom_field_value.name }}:
                            </div>
                            <div class="col-6">
                                {{ custom_field_value.value }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Start delete modal -->
        <modal :show="show_cancel_modal" :closable="!loading" @close="show_cancel_modal = null">
            <div slot="header">
                <div class="modal-header">
                    <h5 class="modal-title mt-0">Ondertekening annuleren</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
            </div>

            <div slot="body">
                <div class="text-center" v-if="delete_loading">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <p v-if="!delete_loading">Weet je zeker dat je deze ondertekening wilt annuleren? Alle ondertekenaars worden hiermee op de
                    hoogte gebracht.</p>
            </div>

            <div slot="footer" class="col-12">
                <div style="padding-left: 0.5rem">
                    <button type="button" class="btn btn-danger me-2 float-start" @click="cancelSignRequest()" :disabled="delete_loading">
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                        Annuleren
                    </button>
                    <button type="button" class="btn btn-outline-secondary float-end" data-dismiss="modal"
                            @click="show_cancel_modal = false">Sluiten
                    </button>
                </div>
            </div>
        </modal>
        <!-- End delete modal -->


        <!-- Start preview modal -->
        <modal :show="show_preview_modal" :closable="!loading" @close="show_preview_modal = null" :custom_modal_class="'modal-lg'">
            <div slot="header">
                <div class="modal-header">
                    <h5 class="modal-title mt-0">Ondertekening bekijken</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
            </div>

            <div slot="body">
                <div class="text-center" v-if="preview_loading">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>

                <div v-if="isSigned() && show_preview_modal">
                    <iframe class=""
                            :src="$root.api_url + '/api/signables/' + show_preview_modal.id + '/file?token=' + token + '&view=true'"
                            v-if="show_preview_modal.signed_signable"
                            width="100%"
                            height="500px"
                    />
                </div>

            </div>

            <div slot="footer" class="col-12">
                <div style="padding-left: 0.5rem">
                    <button type="button" class="btn btn-outline-secondary float-end" data-dismiss="modal"
                            @click="show_preview_modal = false">Sluiten
                    </button>
                </div>
            </div>
        </modal>
        <!-- End preview modal -->

        <!-- Start preview modal -->
        <modal :show="auditTrailModal" @close="auditTrailModal = null" :custom_modal_class="'modal-lg'">
            <div slot="header">
                <div class="modal-header" v-if="auditTrailModal">
                    <h5 class="modal-title mt-0">Audit overzicht voor {{ auditTrailModal.name }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>
            </div>

            <div slot="body">
                <div v-if="auditTrailModal">
                    <table class="w-100">
                        <tr>
                            <th>Soort</th>
                            <th style="width: 150px; text-align: right">Datum</th>
                        </tr>
                        <tbody v-for="action of auditTrailModal.actions" :key="action.id">
                            <tr>
                                <td class="align-top">
                                    <span class="badge"
                                          :class="getBadgeClassForActionType(action.type)">{{ getNameForActionType(action.type) }}</span>
                                </td>
                                <td class="align-top" style="text-align: right">{{ action.updated_at | moment('DD-MM-YYYY HH:mm:ss') }}</td>
                            </tr>

                            <tr v-if="action.comment">
                                <td style="word-break: break-all" colspan="2">
                                    <span class="bg-light d-block p-1 mb-4">{{ action.comment }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <div slot="footer" class="col-12">
                <div style="padding-left: 0.5rem">
                    <button type="button" class="btn btn-outline-secondary float-end" data-dismiss="modal" @click="auditTrailModal = null">
                        Sluiten
                    </button>
                </div>
            </div>
        </modal>
        <!-- End preview modal -->
    </div>

</template>

<script>
    import FullscreenLoading from '@/components/FullscreenLoading';
    import StateMixin from '@/mixins/StateMixin';
    import moment from 'moment';
    import Modal from '@/components/Modal';
    import {mapState} from 'vuex';
    import * as Sentry from '@sentry/vue';
    import LanguageFlag from '@/components/LanguageFlag.vue';

    export default {

        components: {
            LanguageFlag,
            Modal,
            FullscreenLoading
        },

        mixins: [
            StateMixin
        ],

        computed: {
            ...mapState({
                company: state => state.company,
                token: state => state.token
            }),
            sendSignRequestDisabled() {
                return this.signrequest.credits_cost > this.company.credits && this.company.current_payment_plan.id === 1/*prepaid, other abbo can go in minus*/;
            }
        },

        data() {
            return {
                loading: false,
                delete_loading: false,
                preview_loading: false,
                show_preview_modal: false,
                show_cancel_modal: false,
                signersBeingEdited: [],
                signrequest: {
                    emails: [],
                    custom_field_values: [],
                    created_at: null,
                    expires_at: null,
                },
                auditTrailModal: null
            };
        },

        mounted() {

            this.getSignRequest();
        },

        methods: {

            async getSignRequest() {

                this.loading = true;

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/sign-requests/' + this.$route.params.id);

                    this.signrequest = response.data;


                    for (let signer of response.data.signers) {
                        signer.verification_methods = await this.getVerificationMethods(signer);
                    }

                    for (let signable of response.data.signables) {
                        if (this.isSigned()) {
                            signable.signed_signable = await this.getSignableFile(signable);
                        }
                    }

                } catch (exception) {
                    this.errormessage = exception;
                }

                this.loading = false;
            },

            async updateSigner(signer) {

                this.loading = true;

                try {
                    await this.$http.put(this.$root.api_url + '/api/sign-requests/' + signer.id + '/update-signer',
                        signer
                    );

                    this.$notify({
                        group: 'default',
                        title: 'Bewerken geslaagd',
                        type: 'succes',
                        duration: 2000,
                    });

                    this.signersBeingEdited = this.signersBeingEdited.filter((signerId) => {
                        return signerId !== signer.id;
                    });

                    await this.getSignRequest();
                } catch (exception) {
                    this.errormessage = exception;
                    this.$notify({
                        group: 'default',
                        title: 'Bewerken mislukt',
                        text: 'Controleer het emailadres en je rechten',
                        type: 'error',
                        duration: 2000,
                    });

                } finally {
                    this.loading = false;
                }
            },

            async sendManualReminder(signer) {
                this.loading = true;

                try {
                    await this.$http.put(this.$root.api_url + '/api/sign-requests/' + signer.id + '/send-manual-reminder');
                    this.$notify({
                        group: 'default',
                        title: 'Handmatige herrinering verstuurd',
                        type: 'succes',
                        duration: 5000,
                    });

                } catch (exception) {
                    this.errormessage = exception;
                    this.$notify({
                        group: 'default',
                        title: 'Handmatige herrinering niet verstuurd',
                        text: 'De laatste herrinering is mogelijk te recentelijk gestuurd, probeer het later nog eens.',
                        type: 'error',
                        duration: 5000,
                    });
                } finally {
                    this.loading = false;
                    await this.getSignRequest();
                }
            },

            async markSignRequestAsReady() {
                this.loading = true;

                try {
                    await this.$http.post(this.$root.api_url + `/api/sign-requests/${this.signrequest.id}/ready`);

                    await this.getSignRequest();


                    this.$notify({
                        group: 'default',
                        title: 'Ondertekening verstuurd',
                        text: 'Goed nieuws! De ondertekening is onderwerg naar de ontvanger(s)',
                        type: 'success',
                        duration: 2000,
                    });


                    this.$store.dispatch('updateCompany');
                } catch (exception) {
                    // Log the error for sentry...
                    console.error(JSON.stringify(exception?.response?.data?.errors));
                    Sentry.captureMessage('Failed to send sign request, timestamp: ' + (new Date()).getTime());

                    this.$notify({
                        group: 'default',
                        title: 'Er is iets mis gegaan',
                        text: exception,
                        type: 'error',
                    });

                    this.errormessage = exception;
                }

                this.loading = false;
            },

            async cancelSignRequest() {


                this.delete_loading = true;

                try {

                    await this.$http.post(`${this.$root.api_url}/api/sign-requests/${this.signrequest.id}/cancel`);

                    await this.$store.dispatch('updateCompany');
                    await this.getSignRequest();

                    this.show_cancel_modal = false;
                } catch (exception) {
                    this.errormessage = exception;
                }

                this.delete_loading = false;

            },

            async getVerificationMethods(signer) {

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/signers/' + signer.id + '/verifications');

                    return response.data.data;

                } catch (exception) {
                    this.errormessage = exception;
                }
            },

            isSigned() {
                return this.signrequest.state_id === 5;
            },

            downloadPDF(signable) {

                const linkSource = `data:application/pdf;base64,${signable.signed_signable}`;
                const downloadLink = document.createElement('a');
                const fileName = signable.name;
                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();

            },

            previewPDF(signable) {
                this.show_preview_modal = signable;
            },

            async getSignableFile(signable) {

                try {

                    const response = await this.$http.get(this.$root.api_url + '/api/signables/' + signable.id + '/file', {
                        responseType: 'arraybuffer',
                        responseEncoding: 'binary',
                        headers: {
                            'Content-Type': 'application/pdf'
                        }
                    });

                    return (Buffer.from(response.data, 'binary').toString('base64'));
                } catch (exception) {
                    this.errormessage = exception;
                    console.log(exception);
                }
            },

            getReminderAfterDate(signer) {

                return moment(this.signrequest.created_at).add('days', signer.reminder_after).format('DD-MM-YYYY HH:mm');

            },

            getNameForActionType(type) {
                switch (type) {
                    case 1: // Sign request notification sent,
                        return 'Verstuurd';
                    case 2: // Sign request opened,
                        return 'Geopend';
                    case 3: // Approved,
                        return 'Goedgekeurd';
                    case 4: // Declined,
                        return 'Afgekeurd';
                    case 5: // Email verification sent,
                        return 'Email verificatie verstuurd';
                    case 6: // Email verification verified,
                        return 'Email verificatie geverifieerd';
                    case 7: // Signature verification verified,
                        return 'Handtekening verificatie geverifieerd';
                    case 8: // Signables notification sent,
                        return 'Document verificatie geverifieerd';
                    case 10: // SMS verification sent,
                        return 'SMS verificatie verstuurd';
                    case 11: // SMS verification verified,
                        return 'SMS verificatie geverifieerd';
                    case 12: // IDIN verification sent,
                        return 'iDIN verificatie gestart';
                    case 13: // IDIN verification failed,
                        return 'iDIN verificatie gefaald';
                    case 14: // IDIN verification verified,
                        return 'iDIN verificatie geverifieerd';
                    case 15: // SHA 256 checksum signed file
                        return 'SHA256 checksum toegevoegd ondertekend document';
                    case 16: // SHA 256 checksum original file
                        return 'SHA256 checksum toegevoegd origineel document';
                    case 18: // Manual sign request notification sent
                        return 'Handmatige herinnering verstuurd';
                    case 19: // Signer was edited
                        return 'Ondertekenaar aangepast';
                }

                return type;
            },

            getBadgeClassForActionType(type) {
                switch (type) {
                    case 2: // Sign request opened,
                    case 15: // Sha256 checksum added
                    case 16: // Sha256 checksum added
                        return 'bg-primary';
                    case 4: // Declined,
                    case 13: // Declined,
                        return 'bg-warning';
                    case 1: // Sign request notification sent,
                    case 5: // Email verification sent,
                    case 10: // SMS verification sent,
                    case 12: // SMS verification sent,
                    case 18: // Manual sign request notification sent
                        return 'bg-info';
                    case 3: // Approved,
                    case 6: // Email verification verified,
                    case 7: // Signature verification verified,
                    case 8: // Signables notification sent,
                    case 11: // SMS verification verified,
                    case 14: // SMS verification verified,
                    case 19: // Signer edited
                        return 'bg-success';
                }

                return type;
            },
            signerCanBeEdited(signer) {
                if (signer.state_id !== 3 /*in progress*/ && signer.state_id !== 1 /* created */) {
                    return false;
                }

                if (this.signrequest.state_id !== 3 /*in progress*/ && this.signrequest.state_id !== 1 /* created */) {
                    return false;
                }

                return true;
            }
        },

    };
</script>

<style scoped>


    .entity_block {

        min-height: 240px;
        border-bottom: 1px solid #cfcfcf;

    }

    .language-flag {
        margin-right: 5px;
    }

</style>