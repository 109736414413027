<template>
    <div class="container-fluid d-flex flex-column ">


        <div class="row justify-content-center fill-width">
            <div class="col-md-6 col-lg-5 col-xl-4 py-6 py-md-0 mt-5">
                <div class="card" style="max-width: 600px; margin: 0 auto;">
                    <div class="card-body">

                        <div>
                            <div class="mb-5 mt-5 text-center">
                                <h3 class="h3-green" style="font-size:40px;">Inloggen</h3>
                                <p><strong>Vul hieronder je gegevens in om te in loggen.</strong></p>
                            </div>

                            <span class="clearfix"></span>

                            <ValidationObserver ref="login" v-slot="{ invalid }" tag="form">

                                <form ref="loginForm" @submit.prevent="login">
                                    <template v-if="!two_factor_hash">
                                        <div class="form-group mb-3">
                                            <label class="form-control-label">Emailadres: <sup>*</sup></label>
                                            <div class="">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <input :disabled="loading" type="email" v-model="request.email" class="form-control fusion-form-input" id="email" name="email" placeholder="">
                                                    <small class="error">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </div>
                                        </div>

                                        <div class="form-group mb-3">
                                            <label class="form-control-label">Wachtwoord: <sup>*</sup></label>
                                            <div class="">
                                                <validation-provider rules="required" v-slot="{ errors }">
                                                    <input :disabled="loading" type="password" v-model="request.password" class="form-control" placeholder="" name="password">
                                                    <small class="error">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </div>
                                        </div>

                                        <div class="mt-1">
                                            <button class="btn btn-primary btn-caps" type="submit" :disabled="invalid || loading" id="login-button">
                                                Inloggen
                                            </button>
                                        </div>
                                    </template>

                                    <template v-if="two_factor_hash">
                                        <div class="form-group">
                                            <p class="lh-sm">Wij hebben je een tweestaps code per mail verstuurd, vul deze alsjeblieft hieronder in om door te gaan met inloggen:</p>
                                        </div>

                                        <div class="form-group mb-3">
                                            <label class="form-control-label">Code: <sup>*</sup></label>
                                            <validation-provider rules="required" v-slot="{ errors }">
                                                <div class="two-factor-code">
                                                    <input
                                                        v-for="(_, i) in Array(5)"
                                                        :key="i"
                                                        :disabled="loading"
                                                        :autofocus="i === 0"
                                                        type="tel"
                                                        v-model="request.two_factor_code[i]"
                                                        class="form-control fusion-form-input"
                                                        name="two_factor_code"
                                                        maxlength="1"
                                                        @paste.prevent="twoFactorPaste"
                                                        @input="twoFactorInput"
                                                        @keyup="twoFactorKeyup"
                                                    />
                                                </div>
                                                <small class="error">{{ errors[0] }}</small>
                                            </validation-provider>
                                        </div>

                                        <div class="form-group mt-3">
                                            <a @click="clearTwoFactor" class="cursor-pointer">
                                                <font-awesome-icon icon="chevron-left" class="me-2" />
                                                <span>Terug naar inloggen</span>
                                            </a>
                                        </div>
                                    </template>

                                    <div class="text-center form-group mt-3">
                                        <small>
                                            <router-link :to="`/wachtwoord-vergeten`" id="login-password-reset-link">Wachtwoord vergeten</router-link>
                                        </small> |
                                        <small>
                                            <router-link :to="`/register`" id="login-register-link">Registreer</router-link>
                                        </small>
                                    </div>


                                    <div class="alert alert-danger mt-5" v-if="errormessage">{{ errormessage }}</div>
                                </form>

                            </ValidationObserver>

                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

    import {extend, ValidationObserver, ValidationProvider} from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'Dit veld is verplicht'
    });

    export default {

        components: {
            ValidationProvider,
            ValidationObserver,
        },

        data() {
            return {
                request: {
                    email: null,
                    password: null,
                    two_factor_code: [],
                },
                errormessage: null,
                loading: false,
                two_factor_hash: null,
            };
        },

        methods: {
            async login() {
                this.errormessage = null;
                this.loading = true;

                try {
                    const data = {
                        email: this.request.email,
                        password: this.request.password,
                    };

                    if (this.two_factor_hash) {
                        data.two_factor_hash = this.two_factor_hash;
                        data.two_factor_code = this.request.two_factor_code.join('');
                    }

                    const response = await this.$http.post(
                        `${this.$root.api_url}/api/auth/login`,
                        data,
                    );

                    if (response.status === 202) {
                        this.two_factor_hash = response.data.two_factor_hash;
                        this.setFormFocus();
                        return;
                    }

                    await this.$store.dispatch('login', {
                        user: response.data.user,
                        token: response.data.token,
                    });

                    await this.$router.push('/');

                } catch (e) {
                    this.request.two_factor_code = [];
                    this.errormessage = this.request.two_factor_code?.length
                        ? 'De opgegeven code is onjuist, probeer het opnieuw'
                        : 'Het inloggen is helaas niet gelukt, Controleer je gegevens en probeer het opnieuw';

                    this.setFormFocus();

                } finally {
                    this.loading = false;
                    if (!this.two_factor_hash) {
                        this.request.password = null;
                    }
                }
            },

            twoFactorPaste(e) {
                const pasted = (e.clipboardData || window.clipboardData)
                    .getData('text')
                    ?.replace(/[^0-9]/, '');

                if (pasted.length !== 5) {
                    return;
                }

                this.request.two_factor_code = pasted.split('');
                this.login();
            },

            clearTwoFactor() {
                this.request.two_factor_code = [];
                this.request.password = null;
                this.two_factor_hash = null;
            },

            twoFactorInput(e) {
                const code = this.request.two_factor_code?.join('');

                const next = e.target.nextElementSibling;
                if (e.target.value && next instanceof HTMLInputElement) {
                    next?.focus();
                }

                if (code.length === 5) {
                    this.login();
                }
            },

            twoFactorKeyup(e) {
                if (e.key === 'Backspace' && e.target.value === '') {
                    e.target.previousElementSibling?.select();
                }
            },

            setFormFocus() {
                this.$nextTick(() => {
                    this.$refs.loginForm.querySelector('input[autofocus]')?.focus();
                });
            }
        }
    }

</script>

<style scoped>
    .two-factor-code {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 20px;

        input {
            font-weight: bold;
            font-size: 110%;
            text-align: center;
            appearance: none;
            -moz-appearance: none;
            flex: 1 1 1px;

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }
    }
</style>
