<template>
    <div>
        <div class="home mt-5">

            <fullscreen-loading v-if="loading"></fullscreen-loading>

            <div class="fusion-column-wrapper fusion-flex-justify-content-flex-start fusion-content-layout-column">
                <div class="row">
                    <div class="col-12">
                        <div class="fusion-title">
                            <h3 class="h3-green" style="font-size:50px;">Instellingen</h3>
                        </div>
                        <p>
                            Hieronder is het mogelijk verschillende instellingen te maken waaronder wachtwoord instellingen en tweestapsauthenticatie instellingen.
                        </p>

                    </div>
                </div>
            </div>


            <div class="row mb-5">

                <div class="col-md-3 mb-3">
                    <settings-menu></settings-menu>
                </div>


                <div class="col-md-9">
                    <h2 class="title-heading-left " style="">Account instellingen <sup v-tooltip="'Hier is het mogelijk om persoonlijke instellingen te wijzigen.'">?</sup></h2>

                    <ValidationObserver ref="accountsettings" v-slot="{ invalid }" tag="form" class="mt-3">

                        <h2 class="title-heading-left mt-5" style="">Wachtwoord wijzigen <sup v-tooltip="'Wijzig regelmatig je wachtwoord en kies een sterk wachtwoord met letters, cijfers en tekens.'">?</sup></h2>

                        <div class="row mb-3 mt-3">
                            <div class="col-6">

                                <validation-provider rules="required" v-slot="{ errors }">
                                    <router-link :to="{name: 'PasswordChange'}" class="btn btn-primary w-50 mb-3">
                                        <font-awesome-icon class="menu-icon" icon="user-circle"></font-awesome-icon>
                                        Wachtwoord wijzigen
                                    </router-link>
                                    <small class="error">{{ errors[0] }}</small>
                                </validation-provider>
                            </div>
                        </div>

                        <h2 class="title-heading-left mt-5">Tweestapsauthenticatie<sup v-tooltip="'Tijdens het inloggen krijg je een extra e-mail met een code'">?</sup></h2>

                        <div class="row mb-3 mt-3">
                            <div class="col-12">
                                <input type="checkbox" class="custom-checkbox" id="two-factor-auth" v-model="settings.two_factor_auth">
                                <label style="top:0;cursor:pointer;" for="two-factor-auth">Activeren</label>
                            </div>
                        </div>

                         <button class="btn btn-success float-end btn-caps mt-3 " type="button" @click="saveData()" :disabled="invalid">
                            <font-awesome-icon icon="paper-plane" />
                            Opslaan
                        </button>

                    </ValidationObserver>

                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-danger" v-if="errormessage">
                                {{ errormessage }}
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import FullscreenLoading from "@/components/FullscreenLoading";
    import SettingsMenu from "@/components/SettingsMenu";
    import {mapState} from "vuex";
    import {ValidationObserver, ValidationProvider} from "vee-validate";

    export default {
        name: "AccountSettings",
        components: {
            SettingsMenu,
            FullscreenLoading,
            ValidationObserver,
            ValidationProvider,
        },

        data() {
            return {
                loading: false,
                errormessage: null,
                settings: {
                    two_factor_auth: false,
                }
            }
        },

        async mounted() {
            this.settings.two_factor_auth = this.user.two_factor_auth;
        },

        computed: {
            ...mapState({
                user: state => state.user,
            }),
        },

        methods: {
            async saveData() {
                this.loading = true;

                await this.saveUser();

                this.loading = false;
            },

            async saveUser() {
                this.errormessage = null;

                try {
                    const response = await this.$http.put(`${this.$root.api_url}/api/users/${this.user.id}`, {
                        ...this.settings,
                    });

                    this.$notify({
                        group: 'default',
                        title: 'Gegevens opgeslagen',
                        text: 'Je wijzigingen zijn opgeslagen',
                        type: 'success',
                        // duration: 2000,
                    });

                    this.$store.commit('setUser', response.data);
                } catch (exception) {
                    this.errormessage = exception.response.data;
                }
            },
        },
    };
</script>

<style scoped>

</style>